import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { until } from 'protractor';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { UserService } from './user.service';

//Menu Bar
export interface Menu {
  headTitle?: string;
  title?: string;
  path?: string;
  icon?: string;
  type?: string;
  badgeClass?: string;
  badgeValue?: string;
  active?: boolean;
  module?: string,
  children?: Menu[];
  section?:number
}

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  public megaMenu: boolean = false;
  public megaMenuCollapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  private permissions: any;
  
  constructor(
    private router: Router,
    private users: UserService
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize').pipe(
      debounceTime(1000),
      takeUntil(this.unsubscriber)
    ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
      if (evt.target.innerwidth < 991) {
        this.collapseSidebar = false;
        this.megaMenu = false;
      }

      if (evt.target.innerWidth < 1199) {
        this.megaMenuCollapse = true;
      }
    });
    if (window.innerWidth < 991) {
      this.router.events.subscribe(event => {
        this.collapseSidebar = false;
        this.megaMenu = false;
      })
    }
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getModules(){ 

    let MENUITEMS : Menu[] = [
      //mi kiosko
      { headTitle: 'Mi Kiosko', section:0},
      { path: '/analitics', title: 'Analíticos', type: 'link', icon: 'mdi mdi-apps', active: false, module: "analitics", section:0 },
      { path: '/payroll', title: 'Carga de Información', type: 'link', icon: 'zmdi zmdi-dns', active: false, module: "payroll", section:0 },
      { path: '/letters', title: 'Cartas', type: 'link', icon: 'zmdi zmdi-email', active: false, module: "letters", section:0 },
      { path: '/cafeteria', title: 'Comedor', type: 'link', icon: 'zmdi zmdi-cutlery', active: false, module: "cafeteria", section:0 },
      { path: '/credentials', title: 'Credenciales', type: 'link', icon: 'mdi mdi-account-card-details', active: false, module: "credentials", section:0 },
      { path: '/employees', title: 'Empleados', type: 'link', icon: 'mdi mdi-account-multiple', active: false, module: "employees", section:0 },
      { path: '/surveys', title: 'Encuestas', type: 'link', icon: 'mdi mdi-view-list', active: false, module: "surveys", section:0 },
      { path: '/metrics', title: 'Métricos', type: 'link', icon: 'fa fa-bar-chart', active: false, module: "metrics", section:0 },
      { path: '/news', title: 'Noticias', type: 'link', icon: 'zmdi zmdi-collection-text', active: false, module: "news", section:0 },
      { path: '/receipts', title: 'Recibos', type: 'link', icon: 'zmdi zmdi-file-text', active: false, module: "receipts", section:0 },
      { path: '/reports', title: 'Reportes', type: 'link', icon: 'zmdi zmdi-assignment', active: false, module: "reports", section:0 },
      { path: '/routes', title: 'Transporte', type: 'link', icon: 'mdi mdi-bus', active: false, module: "routes", section:0 },
      { path: '/uniforms', title: 'Uniformes', type: 'link', icon: 'mdi mdi-archive', active: false, module: "uniforms", section:0 }, //revisar icono
      { path: '/vacations', title: 'Vacaciones', type: 'link', icon: 'fa fa-umbrella-beach', active: false, module: "vacations", section:0 },
      // { title: 'Evaluaciones', type: 'sub', icon: 'fa fa-tasks', active: false, section:0, module: "evaluations", children: evaluations },
     
      //Evaluaciones
      { headTitle: 'Evaluaciones', section: 2 },
      {path: '/goals', title: "Objetivos", type: 'link', icon: 'fa fa-star', module: "goals", active:false ,section:2 },// Goals
      {path: '/reviews', title: "Revision", type: 'link',icon: 'fa fa-tasks', module: "reviews", active:false, section:2 },// Review
      //checador
      { headTitle: 'Checador', section: 3 },
      { path: '/checker', title: 'Checador', type: 'link', icon: 'mdi mdi-cellphone', active: false, module: "checker", section:1 },
      { path: '/checker-bus', title: 'Checador BUS', type: 'link', icon: 'mdi mdi-bus', active: false, module: "checker-bus", section:1 },
      //inventario
      { headTitle: 'Inventario', section:4 },
      { path: '/inventory', title: 'Inventario', type: 'link', icon: 'mdi mdi-cube', active: false, module: "inventory", section:2 },
      //usuarios
      { headTitle: 'Cuenta', section:5 },
      { path: '/users', title: 'Usuarios', type: 'link', icon: 'mdi mdi-account', active: false, module: "users", section:3 },
      //{ path: '/', title: 'Facturación', type: 'link', icon: 'fa fa-credit-card', active: false },
      { path: '/supports', title: 'Soporte', type: 'link', icon: 'fa fa-question-circle', active: false, module: "supports", section:3 },
    ]

    this.permissions = this.users.user.permissions;
    if (this.users.user.roleName != "ADMIN") {
      //Elimina los modulos que se tiene permisos
      MENUITEMS = MENUITEMS.filter(menu => !menu.module || this.permissions[menu.module]?.access);
      const index = MENUITEMS.findIndex(menu => menu.module);
      MENUITEMS[index].active = true;      

      //Elimina los titulos que no cuentan con modulo
      let hist = {};
      MENUITEMS.map( function (a) { if (a.section in hist) hist[a.section] ++; else hist[a.section] = 1; } );
      MENUITEMS = MENUITEMS.filter(menu => hist[menu.section] > 1);
    }
    return new BehaviorSubject<Menu[]>(MENUITEMS);
  }
  
}
